import React, { useState, createContext, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./UserPool";

const UserContext = createContext();

const UserProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState("");
  const [session, setSession] = useState();
  // console.log({ isLoggedIn });

  useEffect(() => {
    getSession().then((session) => {
      // console.log("Session: ", session);
      setIsLoggedIn(true);
      setSession(session);
    });
  }, []);

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
            // console.log(session);
          }
        });
      } else {
        setIsLoggedIn(false);
      }
    });
  };
  const authenticate = async (Username, Password, newPassword) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      var sessionUserAttributes;
      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          // console.log("onSuccess: ", data);
          resolve(data);

          setIsLoggedIn(true);
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified;
          delete userAttributes.email;
          sessionUserAttributes = userAttributes;
          console.log(sessionUserAttributes);
          user.completeNewPasswordChallenge(
            newPassword,
            sessionUserAttributes,
            {
              onSuccess: (data) => {
                resolve(data);
                console.log(data);
                console.log("contraseña cambiada");
              },
              onFailure: (err) => {
                console.error("onFailure: ", err);
                reject(err);
              },
            }
          );
        },
      });
    });
  };
  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      setIsLoggedIn(false);
    }
  };

  const update = async (attributeList) => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            // resolve(session);
          }
        });
        user.updateAttributes(attributeList, function (err, result) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }
          resolve(result);
          console.log("call result: " + result);
        });
      } else {
        setIsLoggedIn(false);
      }
    });
  };

  return (
    <UserContext.Provider
      value={{
        authenticate,
        getSession,
        session,
        logout,
        isLoggedIn,
        setIsLoggedIn,
        update,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
