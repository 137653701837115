import { CognitoUserPool } from "amazon-cognito-identity-js";

// DESARROLLO
const UserPoolIdDEV = "eu-west-1_jApkvkSmR";
const ClientIdDEV = "3sj66iglh6u0o4o6m0dlmlh3j";

// // PRODUCCION
// const UserPoolIdPROD = "eu-west-1_Hl2FDENFA";
// const ClientIdPROD = "50d53669nl49i1gdkivgqh8bqi";

const poolData = {
  UserPoolId: process.env.GATSBY_USER_POOL_ID || UserPoolIdDEV,
  ClientId: process.env.GATSBY_CLIENT_ID || ClientIdDEV,
};

export default new CognitoUserPool(poolData);
