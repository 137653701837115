import React from "react";
import { Helmet } from "react-helmet";
import "./SeoHelmet.css";
import logoHeader from "../images/logo-header.svg";
import redEs from "../images/redes.svg";
import feder from "../images/feder.png";
import ministerio from "../images/ministerio.png";
import styled from "styled-components";

export const BUILD_ENV = process.env.GATSBY_BUILD_ENV || "local-environment";

const SeoHelmet = ({ children }) => {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CADT | Covid Analysis Decision Tool</title>
        <meta
          name="description"
          content="Application of artificial intelligence in Covid 19"
        />
        {BUILD_ENV === "dev" ? <meta name="robots" content="noindex" /> : null}
        {BUILD_ENV === "production" ? (
          <meta
            name="google-site-verification"
            content="LhiNgiqS1LptOrIP-3m6pRK6Ck4Tzo48vCjg_QRg-uU"
          />
        ) : (
          <meta
            name="google-site-verification"
            content="dH9Xx6cuz8yXLDrKHOPMvzhcBKKOnUOGKTrD-dGPoII"
          />
        )}
      </Helmet>

      <div>{children}</div>
      <footer>
        <p>© 2022 PentaQuark.</p>

        <Sponsors>
          <img
            className="ind"
            src={ministerio}
            alt={ministerio}
            style={{
              cursor: "pointer",
              maxWidth: "140px",
              padding: "0px 5px 5px 0px",
            }}
          />
          <img
            className="ind"
            src={redEs}
            alt={redEs}
            style={{
              cursor: "pointer",
              maxWidth: "60px",
              margin: "10px",
              padding: "0px 5px 5px 0px",
            }}
          />
          <img
            className="ind"
            src={feder}
            alt={feder}
            style={{
              cursor: "pointer",
              maxWidth: "110px",
              margin: "0px 5px",
            }}
          />
        </Sponsors>
        <img
          src={logoHeader}
          alt={logoHeader}
          style={{ maxWidth: "80px", marginLeft: "70px" }}
        ></img>
      </footer>
    </div>
  );
};

export default SeoHelmet;

const Sponsors = styled.button`
  border: none;
  border-radius: 10px;
  background-color: white;
  color: white;
  padding: 0px 10px;
  background: white;
  visibility: visible;
  @media (max-width: 899px) {
    visibility: hidden;
  }
`;
