exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diagnostic-js": () => import("./../../../src/pages/diagnostic.js" /* webpackChunkName: "component---src-pages-diagnostic-js" */),
  "component---src-pages-drugs-js": () => import("./../../../src/pages/drugs.js" /* webpackChunkName: "component---src-pages-drugs-js" */),
  "component---src-pages-epidemiologic-js": () => import("./../../../src/pages/epidemiologic.js" /* webpackChunkName: "component---src-pages-epidemiologic-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-hospital-js": () => import("./../../../src/pages/hospital.js" /* webpackChunkName: "component---src-pages-hospital-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medical-js": () => import("./../../../src/pages/medical.js" /* webpackChunkName: "component---src-pages-medical-js" */),
  "component---src-pages-mesh-js": () => import("./../../../src/pages/mesh.js" /* webpackChunkName: "component---src-pages-mesh-js" */),
  "component---src-pages-newpass-js": () => import("./../../../src/pages/newpass.js" /* webpackChunkName: "component---src-pages-newpass-js" */),
  "component---src-pages-particle-js": () => import("./../../../src/pages/particle.js" /* webpackChunkName: "component---src-pages-particle-js" */),
  "component---src-pages-plantilla-js": () => import("./../../../src/pages/PLANTILLA.js" /* webpackChunkName: "component---src-pages-plantilla-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-radiologic-js": () => import("./../../../src/pages/radiologic.js" /* webpackChunkName: "component---src-pages-radiologic-js" */),
  "component---src-pages-recover-js": () => import("./../../../src/pages/recover.js" /* webpackChunkName: "component---src-pages-recover-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-survival-js": () => import("./../../../src/pages/survival.js" /* webpackChunkName: "component---src-pages-survival-js" */)
}

