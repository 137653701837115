import React from "react";
import Layout from "./src/components/SeoHelmet";
import { UserProvider } from "./src/components/UserProvider";

export const wrapRootElement = ({ element }) => {
  return <UserProvider>{element}</UserProvider>;
};

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};
